import { useEffect, useRef, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurvey, saveSurveyStakeholder, selectSurvey } from "../../store/surveySlice";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import SurveyStakeholder from "../../models/surveyStakeholder";
import { Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import SurveyQuestion from "../../models/surveyQuestion";
import { watch } from "fs";
import BooleanLogicOperationTypes from "../../models/booleanLogicOperationTypes";

interface DetailsRouteParams {
  surveyId?: string;
  surveyStakeholderId?: string;
  parentSurveyStakeholderId?: string;
}

export default function SurveyStakeholderEditScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;
  const isEditing = params.surveyStakeholderId && true;

  const addQuestionRef = useRef<TypeaheadRef>(null);
  const [parentSurveyStakeholderId, setParentSurveyStakeholderId] = useState<number>();
  const [parentSurveyStakeholder, setParentSurveyStakeholder] = useState<SurveyStakeholder>();

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }

      if (params.parentSurveyStakeholderId) {
        setParentSurveyStakeholderId(parseInt(params.parentSurveyStakeholderId));
      }
    }
  }, [dispatch, params.parentSurveyStakeholderId, params.surveyId, survey, surveyState.isLoading]);

  const form = useForm<SurveyStakeholder>();
  const { register, handleSubmit, reset, formState: { errors }, control } = form;
  const { fields, append, remove, move } = useFieldArray({
    name: "values",
    keyName: "valueId",
    control
  });

  const watchOperation = useWatch({
    control: control,
    name: 'operation',
  });
  const watchIsActive = useWatch({
    control: control,
    name: 'isActive',
  });


  const onSubmit: SubmitHandler<SurveyStakeholder> = data => {
    console.log(data);
    const request: SurveyStakeholder = {
      parentSurveyStakeholderId: parentSurveyStakeholderId,
      ...data,
    };

    if ((request.values?.length ?? 0) === 0) {
      return;
    }

    // if (isEditing) {

    // }
    // else {
    //   dispatch(addUpdateDimension(await api.survey(survey.id).dimensions.create(request)));
    // }
    dispatch(saveSurveyStakeholder(survey!.id, request));
    if (request.parentSurveyStakeholderId) {
      navigate(`/surveys/${survey!.id}/stakeholder/${request.parentSurveyStakeholderId}/details`);
    }
    else {
      navigate('../..');
    }
    
    // const request: Project = {
    //   ...data,
    //   partnerId: data.partner?.id ?? data.partnerId,
    //   tags: tags?.filter(t => selectedTagIds.includes(t.id ?? 0))?.map(t => ({ tagId: t.id! })) ?? [], // selectedTags.map((t) => ({ tagId: t.id! })),
    //   attributes: data.attributes?.map((attrib) => {
    //     return {
    //       id: attrib.id,
    //       boolValue: attrib.boolValue,
    //       dateValue: attrib.dateValue,
    //       intValue: attrib.intValue,
    //       stringValue: attrib.stringValue,
    //       choicesValue: (attrib.choicesValue ?
    //         (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
    //         : undefined
    //       ),
    //     };
    //   })
    // };
    // console.log(request);
    // setIsLoading(true);
    // (isEditing ? entityApi.update(request) : entityApi.create(request))
    //   .then((record) => {
    //     navigate(generatePath(ProjectDetailsRoute, { id: `${record.id}` }) + '#resources');
    //     setIsLoading(false);
    //   })
    //   .catch((reason) => {
    //     setError("Unable to load " + label.singular + ": " + reason);
    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    if (parentSurveyStakeholderId) {
      const findParentSurveyTakeholder = survey?.stakeholders?.find(d => d.id === parentSurveyStakeholderId);
      setParentSurveyStakeholder(findParentSurveyTakeholder);
      if (!isEditing && findParentSurveyTakeholder) {
        reset({
          defaultMinimumResponsesRequired: findParentSurveyTakeholder.defaultMinimumResponsesRequired,
        });
      }
    }
  }, [isEditing, parentSurveyStakeholderId, reset, survey?.stakeholders]);

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      let titleParts = [surveyState.survey.name, 'Surveys'];
      if (isEditing) {
        const surveyStakeholderId = parseInt(params.surveyStakeholderId);
        const edit = survey?.stakeholders?.find(d => d.id === surveyStakeholderId);
        titleParts = ['Edit Stakeholder', ...titleParts];
        reset(edit);
        setParentSurveyStakeholderId(edit?.parentSurveyStakeholderId);
      }
      else {
        titleParts = ['Create Stakeholder', ...titleParts];
      }
      setTitle(titleParts);

      // if (parentSurveyStakeholderId) {
      //   const fetchParentSurveyStakeholder = survey?.stakeholders?.find(d => d.id === parentSurveyStakeholderId);
      //   setParentSurveyStakeholder(fetchParentSurveyStakeholder);
      //   reset({
      //     defaultMinimumResponsesRequired: fetchParentSurveyStakeholder?.defaultMinimumResponsesRequired,
      //   });
      // }

    }
  }, [isEditing, params.surveyStakeholderId, reset, survey?.stakeholders, surveyState.isLoading, surveyState.survey]);


  const questions = survey?.questions?.filter(q => q.type === "MC");


  // useEffect(() => {
  //   // Reset form when changing the summary type
  //   //setSelectedQuestionIds([]);
  // }, [watchOperation, ]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        {isEditing ? <li className="breadcrumb-item active">Edit Stakeholder</li> : <li className="breadcrumb-item active">Create Stakeholder</li>}
      </ol>
    </div>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-3">
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                <div>Stakeholder Definition</div>
              </div>
              {survey && <div className="card-body row fw-bold">
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Survey</label>
                  <div className="col-md-9 align-content-center">
                    <div>{survey.name}</div>
                  </div>
                </div>
                {parentSurveyStakeholder && <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-12">Parent Stakeholder</label>
                  <div className="col-md-9 offset-md-3 align-content-center">
                    <Link to={`/surveys/${survey.id}/stakeholder/${parentSurveyStakeholderId}/details`}>{parentSurveyStakeholder?.name}</Link>
                  </div>
                </div>}

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-12">Stakeholder Name</label>
                  <div className="col-md-9 offset-md-3">
                    <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} autoComplete="off" />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-8">Default Supression Response Count</label>
                  <div className="col-md-4">
                    <input type="number" className={"form-control mb-5px " + (errors.defaultMinimumResponsesRequired ? 'is-invalid' : '')} {...register("defaultMinimumResponsesRequired", { required: false })} />
                    {errors.defaultMinimumResponsesRequired && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-8">Filter Type</label>
                  <div className="col-md-4">
                    <select className={"form-control form-control-sm " + (errors.operation ? 'is-invalid' : '')} {...register('operation', { valueAsNumber: true, required: true })}>
                      <option></option>
                      <option value={BooleanLogicOperationTypes.Or}>Any of these</option>
                      <option value={BooleanLogicOperationTypes.And}>All of these</option>
                    </select>
                    {errors.operation && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Status</label>
                  <div className="col-md-9 pt-2">
                    <div className="form-check form-switch mb-2">
                      <input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
                      <label className="form-check-label" htmlFor="isActive"><label className="form-check-label" htmlFor="isActive">{Boolean(watchIsActive) ? 'Active' : 'Disabled'}</label></label>
                    </div>
                  </div>
                </div>

              </div>}
            </div>
          </div>
          <div className="col-md-9">
            {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Choice value(s)
              </div>
              <div className="card-body">
                {watchOperation && <p>
                  A response will be attributed to this stakeholder if it matches {watchOperation === BooleanLogicOperationTypes.Or && <strong>ANY of these</strong>}
                  {watchOperation === BooleanLogicOperationTypes.And && <strong>ALL of these</strong>} selected below.
                </p>}
                {survey && <div className="card-body row fw-bold">
                  {fields.length === 0 && <p>
                    <span className="badge bg-warning">INSTRUCTIONS</span> Set a Filter Type and Select a question from the drop-down below to get started.
                  </p>}
                  {fields.map((field, index) => {
                    const question = questions?.find(q => q.id === field.questionId);
                    return <div className="row mb-2">
                      <div className="col-auto">
                        {question?.name}
                        <div className="small">{question?.text}</div>
                      </div>
                      <div className="col-auto">
                        <select
                          className={"form-control form-control-sm " + (errors?.values?.[index]?.choiceId ? "is-invalid" : "")}
                          {...register(`values.${index}.choiceId` as const, { required: true, valueAsNumber: true, })}
                        >
                          <option></option>
                          {question?.choices?.map(c => <option value={c.id}>{c.choiceText}</option>)}
                        </select>
                        {errors?.values?.[index]?.choiceId && <div className="invalid-feedback">Please select a choice for this question.</div>}
                      </div>
                      <div className="col-1 text-end">
                        <button type="button" className="btn btn-sm btn-outline-danger" onClick={(e) => remove(index)}><i className="fa-solid fa-times"></i></button>
                      </div>
                    </div>;
                  })}
                </div>}
              </div>
              <div className="card-footer">
                {questions && <div className="input-group">
                  <div className="input-prepend">
                    <span className="input-group-text">Add Question</span>
                  </div>
                  <Typeahead
                    ref={addQuestionRef}
                    emptyLabel={'Select a question from the survey...'}
                    multiple={false}
                    labelKey="label"
                    options={questions?.map(q => {
                      return {
                        ...q,
                        label: `${q.name} - ${q.text}`,
                      };
                    })}
                    onChange={(selected) => {
                      const item = selected as SurveyQuestion[];
                      if (item.length > 0) {
                        const q = item[0];
                        append({
                          questionId: q.id,
                          questionName: `${q.name} - ${q.text}`,
                        });
                        addQuestionRef?.current?.clear();
                      }
                    }}
                  /></div>}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button type="submit" className="btn btn-success me-2">Save Stakeholder</button>
            {survey && parentSurveyStakeholder && <Link relative='path' to={`/surveys/${survey.id}/stakeholder/${parentSurveyStakeholderId}/details`} className="btn btn-primary me-2">Back to {parentSurveyStakeholder.name}</Link>}
            {survey && <Link relative='path' to={`/surveys/${survey.id}`} className="btn btn-primary me-2">Back to {survey.name}</Link>}
          </div>
        </div>
      </form>
    </FormProvider>

  </div>);
}