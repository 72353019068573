import { useEffect, useRef, useState } from "react";
import { SurveyReportDataDomainComparison } from "../../../models/surveyReportData";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Point } from "highcharts";
import { SurveyReportStakeholder } from "../../../models/surveyReportStakeholder";
import StakeholderBreadcrumb from "../../../components/survey/stakeholderBreadcrumb";
import { api } from "../../../store/api";
import Survey from "../../../models/survey";
import SurveyReport from "../../../models/surveyReport";
import SurveyReportSchool from "../../../models/surveyReportSchool";

type DomainMatrixAverageComponentProps = {
  report: SurveyReport;
  reportSchool: SurveyReportSchool;
  stakeholders: SurveyReportStakeholder[];
  comparison: SurveyReportDataDomainComparison;
}

export default function DomainMatrixAverageComponent(props: DomainMatrixAverageComponentProps) {
  const { report, reportSchool, comparison, stakeholders } = props;

  const [filterStakeholder, setFilterStakeholder] = useState<SurveyReportStakeholder>();

  return <div className="card mb-2">
    <div className="card-header d-flex flex-row justify-content-between">
      <h4>{comparison.subDomain}</h4>
      <div>
        <StakeholderBreadcrumb stakeholders={stakeholders} filterStakeholder={filterStakeholder} setFilterStakeholder={setFilterStakeholder} />
      </div>
    </div>
    <div className="card-body">
      <ScoreAverageVisualization report={report} reportSchool={reportSchool} stakeholder={filterStakeholder} comparison={comparison} />
    </div>
  </div>;
}


type MissionAndVisionProps = {
  report: SurveyReport;
  reportSchool: SurveyReportSchool;
  stakeholder?: SurveyReportStakeholder;
  comparison: SurveyReportDataDomainComparison;
}

function ScoreAverageVisualization(props: MissionAndVisionProps) {
  const { report, reportSchool, stakeholder, comparison } = props;
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setChartOptions(undefined);
    api.survey(report.surveyId).report(report.id).school(reportSchool.id!)
      .average({
        ...comparison,
        school: reportSchool.id!,
        stakeholder: stakeholder?.surveyStakeholderId,
      })
      .then((data) => {
        setChartOptions({
          chart: {
            type: 'bar',
            //type: 'line',
            events: {
              load: function () {
                // const chart = this,
                //   seriesByCategories = [],
                //   xAxis = chart.xAxis[0];

                // const {
                //   x: axisLineX
                // } = xAxis.axisLine.element.getBBox();

                // chart.series.forEach(s => {
                //   s.points.forEach((point, index) => {
                //     if (point.visible && point.graphic) {
                //       if (!seriesByCategories[index]) {
                //         seriesByCategories[index] = []
                //       }
                //       seriesByCategories[index].push(point)
                //     }
                //   })
                // })

                // seriesByCategories.forEach((series, index) => {
                //   const {
                //     x: firstX,
                //     width: firstWidth
                //   } = series[0].graphic.element.getBBox();
                //   const {
                //     x: lastX,
                //     width: lastWidth
                //   } = series[series.length - 1].graphic.element.getBBox();

                //   chart.renderer.path(['M', axisLineX, chart.plotTop + chart.plotHeight - firstX - firstWidth - 2, 'L', axisLineX, chart.plotTop + chart.plotHeight - lastX + 2, 'z'])
                //     .attr({
                //       stroke: categoriesSegmentColors[index],
                //       'stroke-width': '2px'
                //     })
                //     .add();
                // })
              }
            }
          },

          title: {
            text: comparison.label ?? '',
          },
          xAxis: {
            //type: 'category'
            categories: Object.keys(data.questionAverages!),
            // plotBands: [{ // mark the weekend
            //   color: '#FCFFC5',
            //   from: '2010-01-02',
            //   to: '2010-01-04'
            // }],
          },
          yAxis: [
            {
              labels: {
                style: {
                }
              },
              min: 0,
              max: 5,
              title: {
                text: 'Average Value'
              },
              // plotBands: [
              //   {
              //     color: '#ff0000',
              //     from: 0,
              //     to: 2.0,
              //   }
              // ],
              plotLines: [
                {
                  value: 2.0,
                  // zIndex: 5,
                  width: 1,
                  color: '#ff9999'
                },
                {
                  value: 2.5,
                  // zIndex: 5,
                  width: 2,
                  color: '#ff0000'
                },
                {
                  value: 3.5,
                  // zIndex: 5,
                  width: 2,
                  color: 'orange'
                },
                {
                  value: 4,
                  // zIndex: 5,
                  width: 2,
                  color: '#33ff33'
                },
              ]
            }],
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false,
                format: '{y:.2f}'
              }
            }
          },
          series: [{
            type: 'bar',
            name: data.reportStakeholder?.name ?? 'All Stakeholders',
            dataLabels: {
              enabled: true,
            },
            tooltip: {
              valueDecimals: 2,
              // valueSuffix: '%',
            },
            data: Object.keys(data.questionAverages!).map((seriesName) => data.questionAverages![seriesName]),
            // zones: [{
            //   value: 0,
            //   color: '#ff9999'
            // },
            // {
            //   value: 2,
            //   color: '#ff0000'
            // },
            // {
            //   value: 2.5,
            //   color: 'yellow'
            // },
            // {
            //   value: 3.5,
            //   color: 'green'
            // },
            // {
            //   color: '#99ff99'
            // }]
          }
          ],

        });
        setIsLoading(false);
      })
      .catch((reason) => {
        //setError("Unable to load report schools/systems: " + reason);
        setIsLoading(false);
      });

  }, [comparison, report.id, report.surveyId, reportSchool.id, stakeholder?.surveyStakeholderId]);

  return <>
    {isLoading && <div className="alert text-center"><i className="fa-solid fa-spin fa-spinner"></i> <strong>Loading visualization</strong></div>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}
