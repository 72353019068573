import { useEffect, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { Panel, PanelBody, PanelFooter, PanelHeader } from "../../components/panel/panel";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Resource from "../../models/resource";
import { ResourceDetailsRoute } from "./resourceDetailsScreen";
import { setTitle } from "../../util/useDocumentTitle";
import RecordDetailsCard from "../../components/records/recordDetailsCard";

export const ResourceEditRoute = "/resources/:id/edit";
export const ResourceCreateRoute = "/resources/create";

interface ResourceDeleteScreenParams {
  id?: number;
}

export default function ResourceDeleteScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const label = labels.resource;
  //const entityApi = api.resources;
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [record, setRecord] = useState<Resource>();
  const [error, setError] = useState<String>();

  const params = useParams<keyof ResourceDeleteScreenParams>();


  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.resources.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setTitle([`Delete ${labels.resource.singular}`, record.name!]);
        setIsLoading(false);
        //api.resources.translations(record).count().then(setNumTranslations);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.resource.singular + ` #${params.id}`);
      });
  }, [labels.contact.plural, labels.resource.singular, params.id]);

  const onClickDelete = () => {
    if (record) {
      setIsLoading(true);

      api.resources.delete(record)
        .then(() => {
          setIsLoading(false);
          setIsDeleted(true);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to delete " + labels.resource.singular + ` #${params.id}`);
        });
    }
  };

  return (<div>
    <ol className="breadcrumb float-xl-end">
      <li className="breadcrumb-item"><Link to="..">{label.plural}</Link></li>
      <li className="breadcrumb-item"><Link to=".." relative="path">{record?.name}</Link></li>
      <li className="breadcrumb-item active">Delete</li>
    </ol>
    <h1 className="page-header">Delete {label.singular}: {record?.name}</h1>
    <div className="row">
      <div className="col-md-4">
        {record && <RecordDetailsCard
          record={record}
          type={EntityTypes.Resource}
          editRoute={ResourceEditRoute}
          displayAttributes={[
            {
              label: 'Translation of',
              hide: (record.sourceId ?? 0) <= 0,
              callback: (record: Resource) => <Link to={generatePath(ResourceDetailsRoute, { id: `${record?.sourceId}` })}>{record?.source?.name}</Link>,
            },
          ]}
        />}
      </div>
      <div className="col-md-8">
        {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
        {isDeleted === false && <Panel className="card border-0" isLoading={isLoading}>
          <PanelHeader>Are you sure you wish to delete this {label.singular}?</PanelHeader>
          <PanelBody className="card-body">
            <p>Deleting this resource will remove all {labels.review.plural}, any links to {labels.project.plural}, etc. for this {label.singular}</p>
          </PanelBody>
          <PanelFooter isLoading={isLoading}>
            <button type="button" className="btn btn-danger" disabled={isLoading} onClick={() => onClickDelete()}>Yes, Delete this {label.singular}</button>
          </PanelFooter>
        </Panel>}
        {isDeleted && <Panel className="card border-0" isLoading={isLoading}>
          <PanelHeader>{label.singular} Deleted!</PanelHeader>
          <PanelBody className="card-body">
            <p>
              <i className="fa-solid fa-circle-check text-success"></i> This {label.singular} has been permenantly deleted from the system.
            </p>
          </PanelBody>
        </Panel>}
      </div>
    </div>
  </div>);
}
