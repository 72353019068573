import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Link, generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { Panel, PanelBody } from "../../components/panel/panel";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Project from "../../models/project";
import { PartnerDropdown } from "../../components/partner/partnerDropdown";
import { ProjectDetailsRoute } from "./projectDetailsScreen";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { PartnerDetailsRoute } from "../partners/partnerDetailsScreen";
import { setTitle } from "../../util/useDocumentTitle";
import { TagBadge } from "../../components/tags/tagBadge";
import { TagBrowser } from "../../components/tags/tagBrowser";
import Tag, { tagIsAncestor } from "../../models/tag";

export const ProjectEditRoute = "/projects/:id/edit";
export const ProjectCreateRoute = "/projects/create";

interface ProjectEditScreenParams {
  id?: number;
  partnerId?: number;
}

export default function ProjectEditScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const label = labels.project;
  const entityApi = api.projects;
  const tags = useAppSelector(selectConfig).tags;
  const tagsById = useAppSelector(selectConfig).tagsById;

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<Project>();
  const [error, setError] = useState<String>();
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  
  const params = useParams<keyof ProjectEditScreenParams>();
  const [searchParams, setSearchParams] = useSearchParams();

  // Entity Attributes
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.Project];
  useEffect(() => { dispatch(fetchAttributes(EntityTypes.Project)) }, [dispatch]);

  const form = useForm<Project>();

  const { register, handleSubmit, reset, formState: { errors }, control } = form;
  const { fields, replace } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<Project> = data => {
    console.log(data);
    const request: Project = {
      ...data,
      partnerId: data.partner?.id ?? data.partnerId,
      tags: tags?.filter(t => selectedTagIds.includes(t.id ?? 0))?.map(t => ({ tagId: t.id! })) ?? [], // selectedTags.map((t) => ({ tagId: t.id! })),
      attributes: data.attributes?.map((attrib) => {
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    setIsLoading(true);
    (isEditing ? entityApi.update(request) : entityApi.create(request))
      .then((record) => {
        navigate(generatePath(ProjectDetailsRoute, { id: `${record.id}` }) + '#resources');
        setIsLoading(false);
      })
      .catch((reason) => {
        setError("Unable to load " + label.singular + ": " + reason);
        setIsLoading(false);
      });
  };


  // Bind Suite values to the attribute inputs
  useEffect(() => {
    setTitle([`Edit/Create ${labels.project.singular}`]);
    if (params.id) {
      setIsEditing(true);
      entityApi.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
          setTitle([`Edit ${labels.project.singular}`, record.name!]);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      const partnerIdStr = searchParams.get('partnerId');
      if (partnerIdStr) {
        // load specified partner
        api.partners.get(parseInt(partnerIdStr))
          .then(partner => {
            setTitle([`Create ${labels.project.singular}`]);
            setDefaultValue({
              isActive: true,
              partner,
              partnerId: partner.id,
            });
          });
      }
      else {
        setDefaultValue({
          isActive: true,
          unitEvaluations: true,
        });
      }
    }
  }, [entityApi, label.singular, params.id, searchParams]);

  // Initialize Form with Attributes and defaultValue
  useEffect(() => {
    if (attributes && defaultValue) {
      reset({
        ...defaultValue,
        attributes: attributes.map((attrib) => {
          if (defaultValue?.attributes) {
            const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
            if (attributeValue) {
              // console.warn('replacing field', attributeValue);
              return attributeValue;
            }
          }
          return {
            id: attrib.id,
          }
        }),
      });
      setSelectedTagIds(defaultValue.tags?.map(t => t.tagId) ?? []);
    }
  }, [attributes, replace, defaultValue, reset]);

  const onAddTag = (tag: Tag) => {
    console.error('onAddTag', tag);
    if (tags) {
      if (tag) {
        setSelectedTagIds((existing) => {
          const filtered = existing.filter((tagId) => {
            const existingTag = tags.find(t => t.id === tagId);
            if (existingTag) {
              return !tagIsAncestor(tagsById ?? [], tag, existingTag)
            }
            return false;
          })
          return [...filtered, tag.id!];
        });
        // const filterExistingTagIds = selectedTagIds.filter((t) => {
        //   const existingTag = tags?.find(t => t.id === tagId);
        // })
        // setSelectedTagIds((existing) => [...existing.filter(t => !tagIsAncestor(tags ?? [], tag, t)), tag]);
      }

    }

  };
  //const onRemoveTag = (tagId: number) => setSelectedTagIds((exist) => exist.filter(t => t.id !== tag.id));
  const onRemoveTag = (tagId: number) => setSelectedTagIds((exist) => exist.filter(t => t !== tagId));
  
  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });

  const watchUnitEvaluations = useWatch({
    control: control,
    name: 'unitEvaluations',
  })

  const watchKnowledgeMapType = useWatch({
    control: control,
    name: `knowledgeMapType`
  });

  return (<div>
    <ol className="breadcrumb float-xl-end">
      {defaultValue?.partner && <li className="breadcrumb-item"><Link to={generatePath(PartnerDetailsRoute, { id: `${defaultValue.partner.id}` })}>{defaultValue.partner.name}</Link></li>}
      <li className="breadcrumb-item"><Link to="..">{label.plural}</Link></li>
      {isEditing && <li className="breadcrumb-item active">Edit</li>}
      {!isEditing && <li className="breadcrumb-item active">Create</li>}
    </ol>
    <h1 className="page-header">{label.singular} Details</h1>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Name</label>
                  <div className="col-md-9">
                    <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Partner</label>
                  <div className="col-md-9">
                    <PartnerDropdown />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Knowledge Map Type</label>
                  <div className="col-md-9">
                    <select className={"form-control mb-5px " + (errors.knowledgeMapType ? 'is-invalid' : '')} {...register("knowledgeMapType", { required: true, valueAsNumber: true })}>
                      {AllKnowledgeMapTypes.map((kmType) => <option key={kmType} value={kmType}>{labels.knowledgeMapTypes[kmType]}</option>)}
                    </select>
                    {errors.knowledgeMapType && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">{labels.unitevaluation.plural}</label>
                  <div className="col-md-9 pt-2">
                    <div className="form-check form-switch mb-2">
                      <input className="form-check-input" type="checkbox" id="unitEvaluations" {...register("unitEvaluations")} />
                      <label className="form-check-label" htmlFor="unitEvaluations">{Boolean(watchUnitEvaluations) ? 'Yes' : 'No'}</label>
                    </div>
                  </div>
                </div>

                {fields?.map((field, index) => {
                  const attribute = attributes?.find(attrib => attrib.id === field.id);
                  if (attribute) {
                    console.log('field', attribute.name, field);
                    return (<div key={field.id} className={"row mb-15px" + (attribute.isRequired ? " required" : "")}>
                      <label className="form-label col-form-label col-md-3">{attribute.name}</label>
                      <div className="col-md-9">
                        <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                      </div>
                    </div>
                    );
                  }
                  return <div key={field.id}></div>;
                }
                )}

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Status</label>
                  <div className="col-md-9 pt-2">
                    <div className="form-check form-switch mb-2">
                      <input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
                      <label className="form-check-label" htmlFor="isActive">Active</label>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
          <div className="col-md-6">
            <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {selectedTagIds.length === 0 && <div className="alert alert-warning">No {labels.tag.plural} have been associated with this {label.singular}. Add some from the Available {labels.tag.plural} below.</div>}
                <ul>
                  {selectedTagIds.map((tagId) => <li key={tagId} className="d-flex flex-row mb-1">
                    {tagsById && <TagBadge key={tagId} tags={tagsById} tagId={tagId} />}
                    <button type="button" className="ms-1 px-1 py-0 btn btn-sm btn-outline-warning" onClick={(e) => onRemoveTag(tagId)}>Remove</button>
                  </li>)}
                </ul>
                <TagBrowser
                  //selectedTags={selectedTags}
                  selectedTagIds={selectedTagIds}
                  onAdd={onAddTag}
                  onRemove={onRemoveTag}
                  knowledgeMapType={watchKnowledgeMapType ?? KnowledgeMapTypes.Undefined}
                />
              </PanelBody>
            </Panel>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-footer">
                <div className="text-center">
                  <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                  <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  </div>);
}
