
import { useAppSelector } from '../../store/hooks';
import { Link } from 'react-router-dom';

export default function ShareHeader() {
	// const theme = useAppSelector(selectTheme);
	const session = useAppSelector(state => state.session);

	return (
		<div id="header" className={'app-header '}>
			<div className="navbar-header">
				<Link to="/" className="navbar-brand"><span className="navbar-logo"></span> <b>IEP Tools</b></Link>
			</div>
			<div className="navbar-nav">
				<div className='navbar-item'>
					<div className='navbar-link'>{session.shared?.project?.name}</div>
				</div>
			</div>
		</div>
	)

}
