import Tag from "../../models/tag";

type TagBadgeProps = {
  tags: Record<number, Tag>;
  tagId: number;
  parentTagId?: number;
}

export function TagLabel(props: TagBadgeProps) {
  //const tags = useAppSelector(selectConfig).tagsById;
  const tag = props.tags![props.tagId];
  // const parentTag = props.parentTagId !== undefined
  //   ? tags?.find(t => t.id === props.parentTagId)
  //   : undefined;
  return <>
    {tag?.parentTagId && <TagLabel tags={props.tags} tagId={tag.parentTagId} />}
    {tag?.parentTagId && <i className="fa-solid fa-chevrons-right mx-2"></i>}
    {tag?.label}
  </>;
}

export function TagBadge(props: TagBadgeProps) {
  return <div className="">
    <TagLabel tags={props.tags} tagId={props.tagId} />
  </div>
}