import { FieldErrors, useController, useFormContext } from "react-hook-form";
import Attribute from "../../models/attribute";
import AttributeTypes from "../../models/attributeTypes";
import AttributeValue from "../../models/attributeValue";
import { CustomizableEntity } from "../../models/types";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import AttributeChoice from "../../models/attributeChoice";

type AttributeEditProps = {
  prefix?: string;
  attribute?: Attribute;
  errors: FieldErrors<CustomizableEntity>;
  index: number;
  watch: AttributeValue[] | undefined;
}

export function AttributeValueEditor(props: AttributeEditProps) {
  const { register, getValues, watch } = useFormContext<CustomizableEntity>();
  // const { field: choicesField } = useController({
  //   name: `attributes.${props.index}.choicesValue` as const,

  // });
  const { field: dateField } = useController({
    name: `attributes.${props.index}.dateValue` as const
  });
  const { field: choicesField } = useController({
    name: `attributes.${props.index}.choicesValue` as const
  });

  // const [selectedChoices, setSelectedChoices] = useState<number[]>(() => {
  //   console.log('init selectedChoices', choicesField.value);
  //   return choicesField.value || []; 
  // });
  const [dependantChoice, setDependantChoice] = useState<number[]>();


  // console.log('AttributeValueEditor', props.attribute);
  let dependantAttributeNumber: number = -1;
  if (props.attribute?.dependantOnAttributeId) {
    getValues('attributes')?.forEach((attr, index) => {
      if (attr.id === props.attribute?.dependantOnAttributeId) {
        dependantAttributeNumber = index;
      }
    });
    console.log('AttributeValueEditor is dependant on ', props.attribute?.dependantOnAttributeId, dependantAttributeNumber);
    if (dependantAttributeNumber === -1) {
      console.warn('dependantAttributeNumber not found', props.attribute?.dependantOnAttributeId, getValues('attributes'));
    }
  }

  const attrWatch = watch(`attributes.${dependantAttributeNumber}.choicesValue`);
  useEffect(() => {
    // if (selectedChoices === undefined) {
    //   setSelectedChoices(getValues)
    // }
    // console.log('AttributeValueEditor', props.attribute);
    console.log('AttributeValueEditor is dependant on ', props.attribute?.dependantOnAttributeId, dependantAttributeNumber);
    if (props.attribute?.dependantOnAttributeId) {
      const triggerValue = getValues('attributes')?.filter((attr) => attr.id === props.attribute!.dependantOnAttributeId);
      console.log('triggerValue', props.attribute?.dependantOnAttributeId, triggerValue, getValues('attributes'));
      if (triggerValue && triggerValue.length > 0) {
        //setDependantChoice(Array.isArray(triggerValue[0].choicesValue) ? triggerValue[0].choicesValue : [triggerValue[0].choicesValue]);
        // convert to array
        if (triggerValue[0].choicesValue) {
          const selectedDependantChoices = Array.isArray(triggerValue[0].choicesValue) ? triggerValue[0].choicesValue : [triggerValue[0].choicesValue];
          setDependantChoice(selectedDependantChoices);
          // setDependantChoice(triggerValue[0].choicesValue);
          console.log('triggerValuesetDependantChoice.', selectedDependantChoices);
        }
      }
    }
  }, [attrWatch, getValues, props.attribute, props.attribute?.dependantOnAttributeId]);


  // useEffect(() => {
  //   const defaultChoices = getValues(`attributes.${props.index}`);
  //   console.warn('setSelectedChoices', defaultChoices);
  //   setSelectedChoices(defaultChoices.choicesValue || []);
  // }, [getValues, props.index]);

  const fiterChoice = (choice: AttributeChoice) => {
    if (props.attribute?.dependantOnAttributeId) {
      if (choice.dependantOnAttributeChoiceIds) {
        if (dependantChoice) {
          //console.log('will filter ', choice, dependantChoice);
        }
        else {
          //console.log('would filter ', choice);
        }
        return choice.dependantOnAttributeChoiceIds.filter((requiredChoiceId) => dependantChoice?.includes(requiredChoiceId) ?? false).length > 0;
      }
    }
    return true;
  }

  // useEffect(() => {
  //   // update selected choices
  //   setValue(`attributes.${props.index}.choicesValue`, selectedChoices);
  // }, [props.index, selectedChoices, setValue]);

  // if (props.attribute?.id == 5) {
  //   console.log('choicesField', choicesField.value, selectedChoices);

  // }

  const input = () => {
    switch (props.attribute!.type) {
      case AttributeTypes.String:
        return (<input
          //key={props.field.id}
          type="text"
          placeholder=""
          {...register(`attributes.${props.index}.stringValue` as const, { required: props.attribute!.isRequired })}
          className={"form-control form-control-sm " + (props.errors?.attributes?.[props.index]?.stringValue ? "is-invalid" : "")}
        />);
      case AttributeTypes.Text:
        return (<textarea
          //key={props.field.id}
          placeholder=""
          rows={3}
          {...register(`attributes.${props.index}.stringValue` as const, { required: props.attribute!.isRequired })}
          className={"form-control form-control-sm " + (props.errors?.attributes?.[props.index]?.stringValue ? "is-invalid" : "")}
        />);
      // case AttributeTypes.Date:
      //   return (<input
      //     //key={props.field.id}
      //     type="date"
      //     placeholder=""
      //     {...register(`attributes.${props.index}.dateValue` as const, {
      //       required: props.attribute!.isRequired, valueAsDate: true, value: props.watch?.[props.index]?.dateValue,
      //     })}
      //     className={"form-control form-control-sm " + (props.errors?.attributes?.[props.index]?.dateValue ? "is-invalid" : "")}
      //   />);
      case AttributeTypes.Date:
        return <DatePicker className="form-control" onChange={dateField.onChange} selected={dateField.value} required={props.attribute!.isRequired} />;
      case AttributeTypes.YesNo:
        return (<div className="form-check form-switch mb-2" key={props.attribute?.id}>
          <label className="form-check-label">
            <input className="form-check-input" type="checkbox" {...register(`attributes.${props.index}.boolValue` as const, { valueAsDate: false })} />
            {Boolean(props.watch?.[props.index]?.boolValue) ? 'Yes' : 'No'}
          </label>
        </div>);
      case AttributeTypes.NumberWhole:
        return (<input
          //key={props.field.id}
          type="number"
          placeholder=""
          step={1}
          {...register(`attributes.${props.index}.intValue` as const, { required: props.attribute!.isRequired })}
          className={"form-control form-control-sm " + (props.errors?.attributes?.[props.index]?.stringValue ? "is-invalid" : "")}
        />);
      case AttributeTypes.NumberDecimal:
        return (<input
          //key={props.field.id}
          type="number"
          placeholder=""
          {...register(`attributes.${props.index}.doubleValue` as const, { required: props.attribute!.isRequired })}
          className={"form-control form-control-sm " + (props.errors?.attributes?.[props.index]?.stringValue ? "is-invalid" : "")}
        />);

      case AttributeTypes.Choice:
        if (props.attribute!.format === "radio") {
          return (<div key={props.attribute?.id}>{props.attribute!.choices?.map((choice, choiceIdx) => <div key={choice.id} className="form-check mb-2 form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                //{...register(`attributes.${props.index}.choicesValue` as const)}
                value={choice.id}
                //checked={selectedChoices?.includes(choice.id!) ?? false}
                checked={choicesField.value?.includes(choice.id!) ?? false}
                onChange={(e) => {
                  //setSelectedChoices([parseInt(e.currentTarget.value)]);
                  choicesField.onChange([parseInt(e.currentTarget.value)]);
                }}
              />
              {choice.label}
            </label>
          </div>
          )}
          </div>);
        }

        /// Default presentation: drop-down/select
        return (<div key={props.attribute?.id}>
          <select
            className="form-control"
            {...register(`attributes.${props.index}.choicesValue` as const, {
              valueAsNumber: true,
              // setValueAs: (value) => {
              //   console.log('setValueAs', value);
              //   if (value) {
              //     return [value];
              //   }
              //   return [];
              // },
            })}
            //value={selectedChoices?.map(sel => sel.toString())}
            //defaultValue={selectedChoices?.map(sel => sel.toString())}
            onChange={(e) => {
              console.log('choice onChange', e.currentTarget.value);
              choicesField.onChange([parseInt(e.currentTarget.value)]);
              // if (e.currentTarget.value) {
              //   setSelectedChoices([parseInt(e.currentTarget.value)]);
              //   return;
              // }
              // setSelectedChoices([]);
            }}
          >
            <option value=""></option>
            {props.attribute!.choices
              ?.filter(fiterChoice)
              ?.map((choice, choiceIdx) => <option key={choice.id} value={choice.id}>
                {choice.label}
              </option>)}
          </select>
        </div >);
      case AttributeTypes.MultipleChoice:
        return (<div key={props.attribute?.id}>{props.attribute!.choices?.map((choice, choiceIdx) => <div key={choice.id} className="form-check mb-2 form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type={props.attribute!.type === AttributeTypes.Choice ? 'radio' : 'checkbox'}
              {...register(`attributes.${props.index}.choicesValue` as const, {
                valueAsNumber: true,
                // setValueAs: (value) => {
                //   console.log('checkbox.setValueAs', value);
                //   if (value) {
                //     return [value];
                //   }
                //   return [];
                // },
              })}
              value={choice.id}
              checked={choicesField.value?.includes(choice.id!) ?? false}
              onChange={(e) => {
                const valueCopy = e.target.checked
                  ? [...(choicesField.value || []), choice.id!]
                  : choicesField.value?.filter((item: number) => item !== choice.id) || [];
                // send data to react hook form
                // setSelectedChoices(valueCopy);
                choicesField.onChange(valueCopy);
              }}
            />
            {choice.label}
          </label>
        </div>
        )}
        </div>);
      // return (<div >{props.attribute!.choices?.map((choice) => <div key={choice.id} className="form-check mb-2 form-check-inline">
      //   <label className="form-check-label">
      //     <input className="form-check-input" type="checkbox" {...register(`attributes.${props.index}.choicesValue` as const, { valueAsNumber: true })} value={choice.id} />
      //     {choice.label}
      //   </label>
      // </div>
      // )}
      // </div>);
      // case AttributeTypes.Suite:
      // case AttributeTypes.MultipleSuites:
      //   return (<div key={props.attribute?.id}>
      //     <ReferenceDropdown field={referencesField} multiple={AttributeTypes.MultipleSuites === props.attribute!.type} entityType={EntityTypes.Suite} />            
      //   </div>);
    }
    return <div key={props.attribute?.id}></div>;
  }

  return (<div key={props.attribute?.id}>
    <input
      type="hidden"
      value={props.attribute?.id}
      {...register(`attributes.${props.index}.id` as const, { value: props.attribute?.id, valueAsNumber: true })}
    />
    {input()}
  </div>)
}
