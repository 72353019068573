enum Roles
{
	//None = 'None',
	Admin = 'Admin',
	Reviewer = 'Reviewer',
	Analyst = 'Analyst',
	//KnowledgeMaps = 'KnowledgeMaps',
	SCUser = 'SC User',
	SCAnalyst = 'SC Analyst',
};

export type RoleTypes = keyof typeof Roles;
export default Roles;