import HighchartsReact from "highcharts-react-official";
import SurveyReportMetric from "../../../models/surveyReportMetric";
import Highcharts, { Point } from "highcharts";
import { useRef, useState, useEffect } from "react";
import { api } from "../../../store/api";
import SurveyReport from "../../../models/surveyReport";
import SurveyReportSchool from "../../../models/surveyReportSchool";
import { SurveyReportDataMetricResponse } from "../../../models/surveyReportDataMetricResponse";
import { SurveyReportStakeholder } from "../../../models/surveyReportStakeholder";

type MetricsByDomainProps = {
  report: SurveyReport;
  reportSchool: SurveyReportSchool;

  domain: string;
  metrics: SurveyReportMetric[];
}
export default function MetricsByDomain(props: MetricsByDomainProps) {
  const { report, reportSchool, domain, metrics } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [metricData, setMetricData] = useState<SurveyReportDataMetricResponse>();
  const [rootStakeholders, setRootStakeholders] = useState<SurveyReportStakeholder[]>([]);
  const [parentStakeholders, setParentStakeholders] = useState<SurveyReportStakeholder[]>([]);

  useEffect(() => {
    setIsLoading(true);
    setMetricData(undefined);
    api.survey(report.surveyId).report(report.id).school(reportSchool.id!)
      .metric({
        domain: domain,
        school: reportSchool.id!,
        //stakeholder: stakeholder?.surveyStakeholderId,
      })
      .then((data) => {
        const filteredStakeholders = data.stakeholders.filter(s => (s.parentSurveyStakeholderId ?? 0) === 0);
        setRootStakeholders(filteredStakeholders);
        setParentStakeholders(filteredStakeholders.filter(s => data.stakeholders.find(sub => sub.parentSurveyStakeholderId === s.surveyStakeholderId)));
        setMetricData(data);
        setIsLoading(false);
      })
      .catch((reason) => {
        //setError("Unable to load report schools/systems: " + reason);
        setIsLoading(false);
      });

  }, [domain, report.id, report.surveyId, reportSchool.id]);

  return <div className="my-2">
    <h2 className="text-center">{domain}</h2>
    {isLoading && <div className="alert alert-warning"><i className="fa-solid fa-spin fa-spinner"></i> <strong>Loading {domain}</strong></div>}
    {metricData && <OverallSummary domain={domain} rootStakeholders={rootStakeholders} parentStakeholders={parentStakeholders} data={metricData} />}
    {metricData && <MetricSummary domain={domain} rootStakeholders={rootStakeholders} parentStakeholders={parentStakeholders} data={metricData} />}
  </div>
}


type OverallSummaryProps = {
  domain: string;
  data: SurveyReportDataMetricResponse;
  rootStakeholders: SurveyReportStakeholder[];
  parentStakeholders: SurveyReportStakeholder[];
}

function OverallSummary(props: OverallSummaryProps) {
  const { data, domain, rootStakeholders, parentStakeholders } = props;
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  //const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  //const [isLoading, setIsLoading] = useState(false);


  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      //type: 'line',
    },
    title: {
      text: `Overall ${domain}`,
    },
    xAxis: {
      type: 'category'
    },
    tooltip: {
      valueSuffix: '',
      valueDecimals: 2,

    },
    yAxis: [
      {
        labels: {
          //format: '{value}%',
          style: {
            //color: Highcharts.getOptions().colors[1]
          }
        },
        min: 0,
        //softMax: 8,
        max: 5,
        title: {
          text: 'Average Score'
        },
      }],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{y:.2f}'
        }
      }
    },
    series: [
      {
        type: 'column',
        name: 'All Stakeholders',
        colorByPoint: true,
        tooltip: {
          valueDecimals: 2,
          pointFormatter: function (this: Point): string {
            if (this.y !== undefined) {
              // TODO how many responses

              // const resource = data.resources[this.x];
              // const tag = topics[this.y];
              // const parentTag = tags?.find((d) => d.id === tag.parentTagId);
              // return `${resource.name} ${(this.value ?? 0) === 0 ? 'does not have' : 'has'} tag '${parentTag?.label} > ${tag.label}' in common`;
            }
            return '';
          },
        },

        data: rootStakeholders.map(s => {
          const anySubstakeholders = data.stakeholders
            .find(sub => sub.parentSurveyStakeholderId === s.surveyStakeholderId);
          // console.log('stakeholder vs. anysubs?', s, anySubstakeholders);
          const metricScores = data.scores.filter(score => score.stakeholderId === s.surveyStakeholderId);
          const sumScores = metricScores.map(metricScore => metricScore.sumValue).reduce((a, b) => a + b);
          const countResponses = metricScores.map(metricScore => metricScore.countResponses).reduce((a, b) => a + b)
          return {
            name: s.name,
            y: countResponses > 0 ? sumScores / countResponses : undefined,
            drilldown: anySubstakeholders ? s.name : undefined,
          };
        }),
      },
    ],
    drilldown: {
      breadcrumbs: {
        position: {
          align: 'right'
        }
      },
      series: parentStakeholders.map<Highcharts.SeriesOptionsType>((parent) => {
        const metricScores = data.scores.filter(score => score.stakeholderId === parent.surveyStakeholderId);
        const sumScores = metricScores.map(metricScore => metricScore.sumValue).reduce((a, b) => a + b);
        const countResponses = metricScores.map(metricScore => metricScore.countResponses).reduce((a, b) => a + b)

        const subStakeholders = data.stakeholders
          .filter(sub => sub.parentSurveyStakeholderId === parent.surveyStakeholderId);
        return {
          name: parent.name,
          id: parent.name,
          type: 'column',
          colorByPoint: true,
          data: [
            {
              name: `All ${parent.name}`,
              y: countResponses > 0 ? sumScores / countResponses : undefined,
            },
            ...subStakeholders.map<Highcharts.PointOptionsObject>((sub) => {
              const metricScores = data.scores.filter(score => score.stakeholderId === sub.surveyStakeholderId);
              const sumScores = metricScores.map(metricScore => metricScore.sumValue).reduce((a, b) => a + b);
              const countResponses = metricScores.map(metricScore => metricScore.countResponses).reduce((a, b) => a + b)

              return {
                name: sub.name,
                //type: 'xrange',
                y: countResponses > 0 ? sumScores / countResponses : undefined,
              };
            }),
          ],
        };
      }
      ),


      // series: [
      //   {
      //     name: 'Parents',
      //     id: 'Parents',
      //     type: 'column',
      //     colorByPoint: true,
      //     data: [
      //       { name: 'Parents', y: 4.3 },
      //       { name: 'College Educated', y: 4.8 },
      //       { name: 'White Males', y: 3.8 },
      //       { name: 'Black Females', y: 3.8 },
      //     ],
      //   }
      // ],
    },
  };

  return <div className="my-3">
    <div className="card">
      <div className="card-body">
        {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
      </div>
    </div>
  </div>;
}


function MetricSummary(props: OverallSummaryProps) {
  const { data, domain, rootStakeholders, parentStakeholders } = props;
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const metrics = [...new Set(data.scores.map(score => score.metricName))];

  const chartOptions = {
    chart: {
      type: 'column',
      //type: 'line',
    },
    title: {
      text: domain,
    },
    xAxis: {
      //type: 'category',
      categories: metrics,
    },
    yAxis: [
      {
        labels: {
          //format: '{value}%',
          style: {
            //color: Highcharts.getOptions().colors[1]
          }
        },
        min: 0,
        //softMax: 8,
        max: 5,
        title: {
          text: 'Average Score'
        },
      }],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{y:.2f}'
        }
      }
    },
    series: rootStakeholders.map((stakeholder) => {
      return {
        name: stakeholder.name,
        tooltip: {
          valueDecimals: 2,
          pointFormatter: function (this: Point): string {
            if (this.y !== undefined) {
              // TODO how many responses, for what stakeholder group

              // const resource = data.resources[this.x];
              // const tag = topics[this.y];
              // const parentTag = tags?.find((d) => d.id === tag.parentTagId);
              // return `${resource.name} ${(this.value ?? 0) === 0 ? 'does not have' : 'has'} tag '${parentTag?.label} > ${tag.label}' in common`;
            }
            return '';
          },
        },
        data: metrics.map((metric) => {
          const metricScore = data.scores.find(score => score.metricName === metric && score.stakeholderId === stakeholder.surveyStakeholderId);
          if (metricScore?.countResponses) {
            return metricScore.sumValue / metricScore.countResponses;
          }
          return undefined;
        }),
      }
    }),
    // series: [
    //   {
    //     type: 'column',
    //     name: 'Stakeholders',
    //     colorByPoint: true,
    //     data: rootStakeholders.map(s => {
    //       const anySubstakeholders = data.stakeholders
    //         .find(sub => sub.parentSurveyStakeholderId === s.surveyStakeholderId);
    //       // console.log('stakeholder vs. anysubs?', s, anySubstakeholders);
    //       const metricScores = data.scores.filter(score => score.stakeholderId === s.surveyStakeholderId);
    //       const sumScores = metricScores.map(metricScore => metricScore.sumValue).reduce((a, b) => a + b);
    //       const countResponses = metricScores.map(metricScore => metricScore.countResponses).reduce((a, b) => a + b)
    //       return {
    //         name: s.name,
    //         y: countResponses > 0 ? sumScores / countResponses : undefined,
    //         drilldown: anySubstakeholders ? s.name : undefined,
    //       };
    //     }),
    //   },
    // ],
    // drilldown: {
    //   breadcrumbs: {
    //     position: {
    //       align: 'right'
    //     }
    //   },
    //   series: parentStakeholders.map((parent) => {
    //     const subStakeholders = data.stakeholders
    //       .filter(sub => sub.parentSurveyStakeholderId === parent.surveyStakeholderId);
    //     return {
    //       name: parent.name,
    //       id: parent.name,
    //       type: 'column',
    //       colorByPoint: true,
    //       data: subStakeholders.map((sub) => {
    //         const metricScores = data.scores.filter(score => score.stakeholderId === sub.surveyStakeholderId);
    //         const sumScores = metricScores.map(metricScore => metricScore.sumValue).reduce((a, b) => a + b);
    //         const countResponses = metricScores.map(metricScore => metricScore.countResponses).reduce((a, b) => a + b)

    //         return {
    //           name: sub.name,
    //           //type: 'xrange',
    //           y: countResponses > 0 ? sumScores / countResponses : undefined,
    //         };
    //       }),
    //     };
    //   }
    //   ),
    // },
  };

  return <div className="my-3">
    <div className="card">
      <div className="card-body">
        {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
      </div>
    </div>
  </div>;
}