import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Project from "../../models/project";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { api } from "../../store/api";
import { useEffect, useRef, useState } from "react";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import EntityTypes from "../../models/entityTypes";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import * as Highcharts from 'highcharts';
import highchartsTreegraph from "highcharts/modules/treegraph";
import highchartsTreemap from "highcharts/modules/treemap";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/highcharts-more";
import highchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import highchartsDrilldown from "highcharts/modules/drilldown";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { setTitle } from "../../util/useDocumentTitle";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import { RequiresPermission } from "../../components/role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";
import OCMPReport, { OCMPReportCriteria, OCMPReportGrade } from "../../models/ocmpReport";

export const OCMPReportRoute = "/reports/ccmp";
type OCMPReportScreenProps = {
  project?: Project;
};

export default function OCMPReportScreen(props: OCMPReportScreenProps) {
  const labels = useAppSelector(selectLabels);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectIdStr = searchParams.get('project');
  // const location = useLocation();

  highchartsHeatmap(Highcharts);
  highchartsTreemap(Highcharts);
  highchartsTreegraph(Highcharts);
  highchartsDrilldown(Highcharts);
  highchartsExporting(Highcharts);
  highchartsMore(Highcharts);
  highchartsDrilldown(Highcharts);

  const [hideSelector, setHideSelector] = useState(false);

  const [project, setProject] = useState<Project>();
  const [projects, setProjects] = useState<Project[] | undefined>([]);

  const [data, setData] = useState<OCMPReport>();

  const [error, setError] = useState<String>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.project) {
      setProject(props.project);
    }
  }, [props.project]);

  useEffect(() => {
    if (projectIdStr) {
      api.projects.get(parseInt(projectIdStr)).then((project) => {
        setProject(project);
      });
    }
  }, [projectIdStr]);

  // useEffect(() => {
  //   if (project) {
  //     api.project(project.id!).grades().then(setGrades);
  //   }
  //   else {
  //     setGrades([]);
  //     setAvailableUnits(undefined);
  //     setSelectedGradeUnits(undefined);
  //     setUnits(undefined);
  //   }
  // }, [project]);

  // useEffect(() => {
  //   console.log("fetching units", project, grade);
  //   if (project?.id && grade?.grade) {
  //     api.project(project.id!).grade(grade!.grade).units().then((units) => {
  //       setAvailableUnits(units);
  //       setSelectedGradeUnits([]);
  //     });
  //   }
  //   else {
  //     setUnits(undefined);
  //   }
  // }, [grade, project]);


  // Title
  useEffect(() => {
    setTitle([
      project ? project.name : '',
      `CCMP Report`,
    ]);
  }, [labels.grades, project]);

  const canGenerateReport = project && (true);

  const onGenerateReport = (): React.MouseEventHandler | undefined => {
    setIsLoading(true);
    setError(undefined);
    if (project?.id) {
      api.reports.ocmp(project.id).then((report) => {
        setData(report);
        setIsLoading(false);
      })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load report data: " + reason);
        });

    }
    return undefined;
  };

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Classroom Climate &amp; Multiple Perspectives Reporting</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Reports</li>
          {!hideSelector && project && props.project === undefined && <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${project?.id}` })} >{project.name}</Link></li>}
          <li className="breadcrumb-item active">CCMP Report</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      {hideSelector && <div className="col">
        <button className="p-0 btn" onClick={() => setHideSelector(false)}><i className="fas fa-gear"></i></button>
      </div>}
      {!hideSelector && <div className="col">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none d-flex flex-row justify-content-between align-items-center">
            <h6>Report Parameters</h6>
            <button className="p-0 btn" onClick={() => setHideSelector(true)}><i className="fas fa-eye-slash"></i></button>
          </div>
          <div className="card-body">
            <RequiresPermission permission={PermissionTypes.ProjectSearch}>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-xl-3 text-nowrap"><EntityTypeLabel entityType={EntityTypes.Project} singlular /></label>
                <div className="col-xl-9">
                  <AsyncTypeahead
                    options={projects ?? []}
                    //disabled={partner === undefined}
                    id="projectDropDown"
                    labelKey={"name"}
                    filterBy={['name']}
                    selected={project ? [project] : undefined}
                    minLength={0}
                    isLoading={projects === undefined}
                    clearButton={true}
                    onSearch={function (search: string): void {
                      setProjects(undefined);
                      api.projects.search({ search }).then((result) => {
                        setProjects(result.records);
                      })
                        .catch((reason) => {
                          throw new Error("Error searching for " + labels.project.plural + ": " + reason);
                        });
                    }}
                    onChange={(selected) => {
                      console.warn('onChange', selected);
                      const selectedProjects = selected as Project[];
                      if (selectedProjects.length > 0) {
                        setProject(selectedProjects[0]);
                      }
                      else {
                        setProject(undefined);
                      }
                    }}
                  />
                </div>
              </div>
            </RequiresPermission>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-sm btn-success" disabled={!canGenerateReport} onClick={onGenerateReport}>Generate Report</button>
          </div>
        </div>
      </div>}
      <div className={hideSelector ? "col-md-12" : "col-md-9"}>
        {project && data && <OCMPReportView project={project} data={data} />}
        {project && data && data.grades.map((grade) => <OCMPGradeReport key={grade.grade} criteria={data.criteria} grade={grade} />)}
      </div>
    </div>
  </div>);
}
type OCMPGradeReportProps = {
  criteria: OCMPReportCriteria[];
  grade: OCMPReportGrade;
}
function OCMPGradeReport(props: OCMPGradeReportProps) {
  const { grade, criteria } = props;
  const labels = useAppSelector(selectLabels);

  const units = grade.units.map(u => u.unit);

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const chartOptions: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'bar',
    },

    title: {
      text: `Scores by Unit in Grade ${labels.grades[grade.grade]}`,
    },

    xAxis: {
      categories: units,
        //data.grades.map(score => `Grade ${labels.grades[score.grade]}`),
      // categories: data.map(qualityReport => qualityReport.name),
      //   title: {
      //   text: null,
      //     },
      gridLineWidth: 1,
    },
    tooltip: {
      //valueSuffix: '%',
      pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
    },
    plotOptions: {
      bar: {
        borderRadius: '50%',
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0.1
      }
    },
    legend: {
      // layout: 'bottom',
      //align: 'bottom',
      // verticalAlign: 'top',
      // x: -40,
      // y: 80,
      // floating: true,
      borderWidth: 1,
      shadow: true
    },
    yAxis: [
      {
        min: 0,
        max: criteria.reduce((prev, cur) => Math.max(prev, cur.maxScore), 0),
        id: 'score',
        title: {
          text: 'Average Score',
          align: 'high',
        },
        minorGridLineWidth: 0,
        // lineWidth: 0,
        // opposite: true,
        labels: {
          overflow: 'justify'
        },
        gridLineWidth: 0,
      },
    ],

    series: criteria.map(((question, idx) => {
      return {
        type: 'bar',
        name: question.name,
        //data: data.grades.flatMap((score) => score.scores[question.name]),
        data: grade.units.flatMap((score) => score.scores[question.name]),
        dataLabels: [
          {
            enabled: true,
            distance: -40,
            format: '{point.y:.1f}',
            style: {
              fontSize: '1em',
              textOverflow: 'show',
              // textOutline: 'none',
              opacity: 0.7
            },
            // filter: {
            //   operator: '==',
            //   property: 'x',
            //   value: 0,
            // }
          }
        ],
        tooltip: {
          //format: '',
          valueDecimals: 2,
        },
        //.map((score) =>),
      };
    }))

  };
  return <Panel>
    <PanelHeader>Grade {labels.grades[grade.grade]}</PanelHeader>
    <PanelBody>
      {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
    </PanelBody>
  </Panel>;
}

type OCMPReportViewProps = {
  project: Project;
  data: OCMPReport;
};
function OCMPReportView(props: OCMPReportViewProps) {
  const labels = useAppSelector(selectLabels);
  const { project, data } = props;
  const [isLoading, setIsLoading] = useState(false);

  // const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const chartOptions: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'bar',
    },

    title: {
      text: 'Scores by Grade',
    },

    xAxis: {
      categories: data.grades.map(score => `Grade ${labels.grades[score.grade]}`),
      // categories: data.map(qualityReport => qualityReport.name),
      //   title: {
      //   text: null,
      //     },
      gridLineWidth: 1,
    },
    tooltip: {
      //valueSuffix: '%',
      pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
    },
    plotOptions: {
      bar: {
        borderRadius: '50%',
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0.1
      }
    },
    legend: {
      // layout: 'bottom',
      //align: 'bottom',
      // verticalAlign: 'top',
      // x: -40,
      // y: 80,
      // floating: true,
      borderWidth: 1,
      shadow: true
    },
    yAxis: [
      {
        min: 0,
        max: data.criteria.reduce((prev, cur) => Math.max(prev, cur.maxScore), 0),
        id: 'score',
        title: {
          text: 'Average Score',
          align: 'high',
        },
        minorGridLineWidth: 0,
        // lineWidth: 0,
        // opposite: true,
        labels: {
          overflow: 'justify'
        },
        gridLineWidth: 0,
      },
    ],

    series: data.criteria.map(((question, idx) => {
      return {
        type: 'bar',
        name: question.name,
        data: data.grades.flatMap((score) => score.scores[question.name]),
        dataLabels: [
          {
            enabled: true,
            distance: -40,
            format: '{point.y:.1f}',
            style: {
              fontSize: '1em',
              textOverflow: 'show',
              // textOutline: 'none',
              opacity: 0.7
            },
            // filter: {
            //   operator: '==',
            //   property: 'x',
            //   value: 0,
            // }
          }
        ],
        tooltip: {
          //format: '',
          valueDecimals: 2,
        },
        //.map((score) =>),
      };
    }))

  };

  return (<Panel isLoading={isLoading} >
    <PanelHeader>{labels.project.singular} Summary</PanelHeader>
    <PanelBody>
      {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading chart data...</p>}
      {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
    </PanelBody>
  </Panel>);
}
