import { useEffect, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurvey, selectSurvey } from "../../store/surveySlice";
import { api } from "../../store/api";
import SurveyReport from "../../models/surveyReport";
import SurveyReportSchool from "../../models/surveyReportSchool";
import { format } from "../../helpers/format";
import { SurveyReportPublishRequest } from "../../models/surveyReportPublishRequest";
import { Panel, PanelBody, PanelFooter } from "../../components/panel/panel";


interface DetailsRouteParams {
  surveyId?: string;
  surveyReportId?: string;
}

const getSchools = (schools?: SurveyReportSchool[]): SurveyReportSchool[] => {
  return [...(schools?.flatMap((school) => getSchools(school.schools)) ?? []), ...(schools?.map((school) => school) ?? [])];
};


export default function SurveyReportPublishScreen() {
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;
  const isEditing = params.surveyReportId && true;

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [error, setError] = useState<string>();
  const [sendEmail, setSendEmail] = useState(true);
  const [regenerate, setRegenerate] = useState(false);
  const [surveyReport, setSurveyReport] = useState<SurveyReport>();
  const [selectedSurveyReportSchool, setSelectedSurveyReportSchool] = useState<SurveyReportSchool[]>([]);

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.isLoading]);

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      let titleParts = [surveyState.survey.name, 'Surveys'];
      const surveyReportId = parseInt(params.surveyReportId!);

      setIsLoading(true);
      api.survey(survey!.id!).reports.get(surveyReportId)
        .then((result) => {
          setSurveyReport(result);
          setSelectedSurveyReportSchool(getSchools(result.schools).filter(s => (s.contactEmail?.length ?? 0) > 3 && s.sharedUtc === undefined));
          setIsLoading(false);
          setError(undefined);
        })
        .catch((reason) => {
          console.error(reason);
          setError(`Unable to load report #${params.surveyReportId}`);
          setIsLoading(false);
        });

      titleParts = ['Publish Shared Report', ...titleParts];
      setTitle(titleParts);
    }
  }, [params.surveyReportId, survey, surveyState.isLoading, surveyState.survey]);

  //const schoolQuestion = survey?.questions?.find(q => q.id === survey?.schoolQuestionId);

  const onAddSchool = (added: SurveyReportSchool) => {
    setSelectedSurveyReportSchool(cur => [...cur, added]);
  }
  const onRemoveSchool = (remove: SurveyReportSchool) => {
    setSelectedSurveyReportSchool(cur => cur.filter(item => item.id !== remove.id));
  }

  const onPublish = () => {
    if (surveyReport) {
      const request: SurveyReportPublishRequest = {
        schools: selectedSurveyReportSchool,
        sendEmail,
        regenerate,
      };
      setIsSaving(true);
      api.survey(surveyReport?.surveyId).report(surveyReport?.id).publish(request)
        .then((result) => {
          setSurveyReport(result);
          setError(undefined);
          setIsLoading(false);
          setIsPublished(true);
          setIsSaving(false);
        })
        .catch((reason) => {
          console.error(reason);
          setError(`Unable to publish report #${params.surveyReportId}`);
          setIsLoading(false);
        });
    }
  };

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        {surveyReport && <li className="breadcrumb-item"><Link to={"../edit"} relative="path">{surveyReport.name}</Link></li>}
        {isEditing ? <li className="breadcrumb-item active">Publish Shared Report</li> : <li className="breadcrumb-item active">Create Shared Report</li>}
      </ol>
    </div>
    <div className="row">
      <div className="col-12">
        {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
        <Panel isLoading={isLoading || isSaving}>
          <PanelBody>
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center justify-content-between">
                <div>Report System(s)/School(s)</div>
                <Link className="btn btn-sm btn-outline-primary" to={"../edit"} relative="path">Edit</Link>
              </div>
              <div className="card-body">
                {isLoading && <span>Fetching existing report details...</span>}
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      {isPublished === false && <th></th>}
                      <th>School/System</th>
                      <th>#</th>
                      <th>Contact Person</th>
                      <th>Contact Email</th>
                      <th>Shared</th>
                    </tr>
                  </thead>
                  <tbody>
                    {surveyReport?.schools?.map((school) => <RenderSchool
                      key={school.id}
                      school={school}
                      indent={1}
                      selected={selectedSurveyReportSchool}
                      surveyId={surveyReport.surveyId}
                      onAdd={isPublished ? undefined : onAddSchool}
                      onRemove={isPublished ? undefined : onRemoveSchool}
                    />)}
                  </tbody>
                </table>

                {/* {survey?.id && schoolQuestion && <RenderReports surveyId={survey?.id} schoolQuestion={schoolQuestion} prefix="" indent={0} reportSchools={watchSchools ?? []} />} */}
              </div>
            </div>
          </PanelBody>
          {isPublished === false && <PanelFooter>
            <div className="row">
              <div className="col-lg-6">
                <h6><i className="fa-solid fa-envelope"></i> Send Email to Contacts?</h6>
                <div className="form-check form-switch ms-auto mb-0">
                  <input type="checkbox" className="form-check-input" name="" onChange={() => setSendEmail(v => !v)} id="sendEmail" checked={sendEmail} />
                  {sendEmail === true && <label className="form-check-label" htmlFor="sendEmail">Yes, send an email to each contact when their report is published.</label>}
                  {sendEmail === false && <label className="form-check-label" htmlFor="sendEmail">No, publish the reports but do not send any emails.</label>}
                </div>
              </div>
              <div className="col-lg-6">
                <h6><i className="fa-solid fa-link"></i> Re-generate Links?</h6>
                <div className="form-check form-switch ms-auto mb-0">
                  <input type="checkbox" className="form-check-input" name="" onChange={() => setRegenerate(v => !v)} id="regenerate" checked={regenerate} />
                  {regenerate === true && <label className="form-check-label" htmlFor="regenerate">Yes, regenerate the share links for the reports I publish. Old links will no longer work.</label>}
                  {regenerate === false && <label className="form-check-label" htmlFor="regenerate">No, leave existing share links as-is and working.</label>}
                </div>
              </div>
            </div>
          </PanelFooter>}
          {isPublished && <PanelFooter>
            <div className="alert alert-success">
              <strong>Published!</strong> This report has been published!
              {regenerate && <p>The links for the selected schools have been regenerated.</p>}
              {sendEmail && <p>The contacts at the selected schools have been sent an email with instructions on how to access the interactive dashboard.</p>}
            </div>
            </PanelFooter>}
        </Panel>
      </div>
    </div>
    <div className="row">
      <div className="col">
        {error && <div className="border-1 alert alert-danger"><strong>Error</strong> {error}</div>}
        {isPublished === false && <button type="button" className="btn btn-success me-2" disabled={isSaving || selectedSurveyReportSchool.length === 0} onClick={() => onPublish()}>Publish Report</button>}
        <Link to='../..' className="btn btn-primary me-2">Back to {survey?.name}</Link>
        {surveyReport?.sharedUtc && isPublished === false && <button type="button" className="btn btn-outline-secondary me-2" disabled={isSaving} onClick={() => setIsPublished(true)}>View Published Reports</button>}
      </div>
    </div>

  </div>);

}

type RenderSchoolProps = {
  school: SurveyReportSchool;
  selected: SurveyReportSchool[];
  indent: number;
  surveyId: number;
  onAdd?: (added: SurveyReportSchool) => void;
  onRemove?: (added: SurveyReportSchool) => void;
}
function RenderSchool(props: RenderSchoolProps) {
  const { school, indent, selected, surveyId, onAdd, onRemove } = props;

  const isSelected = selected.find((s) => s.id === school.id) !== undefined;

  const onChangeSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onAdd && onRemove) {
      if (isSelected) {
        onRemove(school);
      }
      else {
        onAdd(school);
      }
    }
  };

  return <>
    <tr>
      {onAdd && onRemove && <td className={`ps-${indent}`}>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={school.id}
            id={`school_${school.id}`}
            disabled={(school.contactEmail ?? '').length <= 3}
            checked={isSelected}
            onChange={onChangeSelected}
          />
        </div>
      </td>}
      <td className={`ps-${indent}`}>
        {school.choice === undefined ? <i className="fa-fw fa-solid fa-building text-primary"></i> : <i className="fa-fw fa-solid fa-school"></i>}
        <label className="form-check-label ms-1" htmlFor={`school_${school.id}`}>
          {school.name ?? school.choice?.choiceText}
        </label>
      </td>
      <td>
        {school.choice && <ResponseCount surveyId={surveyId} choiceId={school.choice.id} />}
      </td>
      <td>
        {school.contactName}<br />
        {school.contactRole}
      </td>
      <td>
        {school.contactEmail}
      </td>
      <td>
        {school.sharedUtc === undefined && <i>Not published</i>}
        {school.sharedUtc && school.shareToken && <ShareLink shareToken={school.shareToken} />}
      </td>
    </tr>
    {school.schools?.map((subschool) => <RenderSchool school={subschool} indent={indent + 1} selected={selected} surveyId={surveyId} onAdd={onAdd} onRemove={onRemove} />)}
  </>;
}

type ShareLinkProps = {
  shareToken: string;
}

function ShareLink(props: ShareLinkProps) {
  const { shareToken } = props;
  const shareUrl = `${window.location.protocol}//${window.location.host}/share/${encodeURIComponent(shareToken)}/surveyReport`;

  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareUrl);
      setIsCopied(true);
      window.setTimeout(() => setIsCopied(false), 1200);
    }
  };

  return <div className="input-group input-group-sm">
    <button className={"btn btn-sm " + (isCopied ? 'btn-success' : 'btn-outline-secondary')} type="button" title="Copy to clipboard" onClick={() => onCopy()}><i className="fa-solid fa-paste"></i></button>
    <input type="text" value={shareUrl} className={"form-control " + (isCopied ? 'border-success' : '')} />
  </div>;
}

type ResponseCountProps = {
  surveyId: number;
  choiceId: number;
}
function ResponseCount(props: ResponseCountProps) {
  const [count, setCount] = useState<number>();

  useEffect(() => {
    api.survey(props.surveyId).responses.count({ choiceId: props.choiceId }).then(setCount);
  }, [props.choiceId, props.surveyId]);

  if (count) {
    return <span title={`${format.number(count)} responses for this school`}>{format.number(count)}</span>;
  }
  return <></>;
}