import { Link, generatePath, useParams } from "react-router-dom";
import { api } from "../../store/api";
import { useEffect, useState } from "react";
import Tag, { tagOrAncestorIsUserManaged } from "../../models/tag";
import { TagsRoute } from "./tagsScreen";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { TagEditRoute } from "./tagEditScreen";
import { selectTags } from "../../store/configSlice";
import tagBreadcrumb from "../../components/tags/tagBreadcrumb";
import TagDetailsCard from "../../components/tags/tagDetailsCard";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { Panel, PanelBody, PanelFooter, PanelHeader } from "../../components/panel/panel";

export const TagDetailsRoute = "/settings/tags/:id";

interface TagDetailsRouteParams {
  id?: string;
}

export default function TagDeleteScreen() {
  const labels = useAppSelector(selectLabels);
  const allTags = useAppSelector(selectTags);
  const label = labels.tag;
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Tag>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof TagDetailsRouteParams>();
  //const [subTagsFilter, setSubTagsFilter] = useState<RecordFilter<Tag>[]>([]);
  const [subTags, setSubTags] = useState<Tag[]>();
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    document.title = 'Tags';
  }, []);

  // useEffect(() => {
  //   if (record) {
  //     setSubTagsFilter([
  //       { key: `sub-tags-${record?.id}`, label: `Sub-${label.plural} (Active)`, api: (search: SearchProps) => api.settings.tags.search({ ...search, parentTagId: record?.id }) },
  //       { key: `sub-tags-${record?.id}-all`, label: `Sub-${label.plural} (All)`, api: (search: SearchProps) => api.settings.tags.search({ ...search, parentTagId: record?.id, includeDisabled: true }) },
  //     ]);
  //   }
  // }, [label.plural, record]);

  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.settings.tags.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        document.title = `Delete ${record.label} - ${labels.tag.plural}`;
        api.settings.tags.subtags(record.id!).then((response) => {
          setSubTags(response);
          setIsLoading(false);
        })
          .catch((reason) => {
            console.error(reason);
            setError("Unable to load sub-" + labels.tag.singular + ` #${params.id}`);
          });
        //setIsLoading(false);
      })
      .catch((reason) => {
        setIsLoading(false);
        console.error(reason);
        setError("Unable to load " + labels.tag.singular + ` #${params.id}`);
      });
  }, [labels.review.singular, labels.tag.plural, labels.tag.singular, params.id]);

  const onClickDelete = () => {
    if (record) {
      setIsLoading(true);

      api.settings.tags.delete(record)
        .then(() => {
          setIsLoading(false);
          setIsDeleted(true);
        })
        .catch((reason) => {
          setIsLoading(false);
          console.error(reason);
          setError("Unable to delete " + labels.resource.singular + ` #${params.id}`);
        });
    }
  };

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Settings</li>
          <li className="breadcrumb-item"><Link to={TagsRoute}>{labels.tag.plural}</Link></li>
          {tagBreadcrumb(record)}
          <li className="breadcrumb-item active">Delete Tag?</li>
        </ul>
      </div>
      <div className="ms-auto">

      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <TagDetailsCard record={record} showExport={false} showDelete={false} isUserManaged={false} />}
      </div>
      <div className="col-md-8">
        {isDeleted === false && <Panel isLoading={isLoading} className="table-responsive mb-3">
          <PanelHeader>Are you sure you wish to delete this {label.singular}?</PanelHeader>
          <PanelBody>
            <p>
              Deleting this {label.singular} will modify all reviews that refernece this {label.singular}.
            </p>
            {(subTags?.length ?? 0) > 0 && <div className="alert alert-danger">
              <strong>Sub-Tags</strong> This Tag is the parent to {subTags?.length} other tags, which will be deleted if you submit this form.
              </div>}
          </PanelBody>
          <PanelFooter isLoading={isLoading}>
            <button type="button" className="btn btn-danger" disabled={isLoading} onClick={() => onClickDelete()}>Yes, Delete this {label.singular}</button>
          </PanelFooter>
        </Panel>}
        {isDeleted && <Panel className="card border-0" isLoading={isLoading}>
          <PanelHeader>{label.singular} Deleted!</PanelHeader>
          <PanelBody className="card-body">
            <p>
              <i className="fa-solid fa-circle-check text-success"></i> This {label.singular} has been permenantly deleted from the system.
            </p>
          </PanelBody>
        </Panel>}
      </div>
    </div>
  </div>);
}

