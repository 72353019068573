import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import EntityTypes from '../models/entityTypes';
import Attribute from '../models/attribute';
import { api } from './api';
import Tag, { tagsByParentId } from '../models/tag';

export interface ConfigState {
    tags?: Tag[];
    tagsById?: Record<number, Tag>;
    subTags?: Record<number, Tag[]>;
    attributes: { [n in EntityTypes as number]?: Attribute[] };
}

const initialState: ConfigState = {
    attributes: {},
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        //increment: (state) => {
        //  // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //  // doesn't actually mutate the state because it uses the Immer library,
        //  // which detects changes to a "draft state" and produces a brand new
        //  // immutable state based off those changes
        //  state.value += 1;
        //},
        //decrement: (state) => {
        //  state.value -= 1;
        //},
        //// Use the PayloadAction type to declare the contents of `action.payload`
        //incrementByAmount: (state, action: PayloadAction<number>) => {
        //  state.value += action.payload;
        //},
        tagDeleted: (state, action: PayloadAction<Tag>) => {
            console.warn('tagDeleted', action.payload);
            state.tags = state.tags?.filter(t => t.id !== action.payload.id) ?? [];
            if (state.subTags && action.payload.parentTagId) {
                const subTags = state.subTags[action.payload.parentTagId];
                if (subTags) {
                    state.subTags[action.payload.parentTagId] = state.subTags[action.payload.parentTagId].filter(t => t.id !== action.payload.id);
                }
            }
        },
        tagUpdated: (state, action: PayloadAction<Tag>) => {
            console.warn('tagUpdated', action.payload);
            const tags = state.tags?.filter(t => t.id !== action.payload.id) ?? [];
            state.tags = [ ...tags, action.payload];
            if (state.tagsById === undefined) {
                state.tagsById = {};
            }
            if (action.payload.id) {
                state.tagsById[action.payload.id] = action.payload;
            }
            if (state.subTags && action.payload.parentTagId) {
                if (state.subTags[action.payload.parentTagId] === undefined) {
                    state.subTags[action.payload.parentTagId] = [];
                }
                state.subTags[action.payload.parentTagId] = [
                    ...state.subTags[action.payload.parentTagId],
                    action.payload,
                ];
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTags.pending, (state) => {
                //state.status = 'loading';
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                console.warn('fetchTags.fulfilled', action.payload);
                state.tags = action.payload;
                state.tagsById = {};
                for(const thisTag of action.payload) {
                    state.tagsById[thisTag.id!] = thisTag;
                }
                state.subTags = tagsByParentId(action.payload);
            })
            .addCase(fetchTags.rejected, (state) => {
                //state.status = 'failed';
            })
            .addCase(fetchAttributes.pending, (state) => {
                //state.status = 'loading';
            })
            .addCase(fetchAttributes.fulfilled, (state, action) => {
                console.warn('fetchAttributes.fulfilled', action.payload);
                state.attributes[action.payload.type] = action.payload.attributes;
                // return {
                //     ...state,
                //     ...action.payload,
                // };
            })
            .addCase(fetchAttributes.rejected, (state) => {
                //state.status = 'failed';
            });
    },
});


export const fetchAttributes = createAsyncThunk<{ attributes: Attribute[]; type: EntityTypes; }, EntityTypes, { state: RootState }>(
    'attributes/fetch',
    async (type: EntityTypes) => {
        const attributes = await api.account.attributes.forEntity(type);
        return {
            type,
            attributes
        };
    },
    {
        condition(type: EntityTypes, { getState, extra }) {
            const { config } = getState();
            if (config.attributes[type]) {
                // Already have attributes: do not dispatch
                console.log('skipping fetch');
                return false;
            }
            return true;
        },
    }
);

export const fetchTags = createAsyncThunk<Tag[]>(
    'tags/fetch',
    async () => await api.settings.tags.all(),
);


//export const {  } = configSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectConfig = (state: RootState) => state.config;
export const selectTags = (state: RootState) => state.config.tags;
export const selectTagsByParentId = (state: RootState) => state.config.subTags;

export default configSlice.reducer;