import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { ReportDataView, ReportSchoolItem } from "../surveys/surveyDataScreen";
import SurveyReportData from "../../models/surveyReportData";
import { api } from "../../store/api";
import SurveyReportSchool from "../../models/surveyReportSchool";
import * as Highcharts from 'highcharts';
import highchartsExporting from "highcharts/modules/exporting";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsDrilldown from "highcharts/modules/drilldown";

export default function SharedSurveyReportScreen() {
  const session = useAppSelector(state => state.session);
  const report = session.shared?.report;
  const userReportSchool = session.shared?.reportSchool;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [hideSelector, setHideSelector] = useState(false);
  const [reportSchool, setReportSchool] = useState<SurveyReportSchool>();
  const [reportData, setReportData] = useState<SurveyReportData>();

  useEffect(() => {
    setReportData(undefined);
  }, [reportSchool]);

  const onViewReport = () => {
    if (report?.id && reportSchool?.id) {
      setError(undefined);
      setIsLoading(true);
      setReportData(undefined);
      api.survey(report!.surveyId).report(report?.id).school(reportSchool?.id).data().then((result) => {
        setIsLoading(false);
        setReportData(result);
      })
        .catch((reason) => {
          setIsLoading(false);
          setError("Unable to load report schools/systems: " + reason);
        });
    }
  };


  highchartsExporting(Highcharts);
  highchartsDrilldown(Highcharts);
  highchartsExportData(Highcharts)

  return <div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        {report && <li className="breadcrumb-item">{report.name}</li>}
        {report && userReportSchool && <li className="breadcrumb-item">{userReportSchool.name}</li>}
      </ol>
    </div>
    <div className="row">
      {error && <div className="alert alert-danger"><strong>Error</strong> {error}</div>}
      {hideSelector && <div className="col">
        <button className="p-0 btn" onClick={() => setHideSelector(false)}><i className="fas fa-gear"></i></button>
      </div>}

      {!hideSelector && <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none d-flex flex-row justify-content-between align-items-center">
            <h6>School/System</h6>
            <button className="p-0 btn" onClick={() => setHideSelector(true)}><i className="fas fa-eye-slash"></i></button>
          </div>

          {report && <div className="card-body row fw-bold">
            <div className="col-12">
              {userReportSchool && <ReportSchoolItem school={userReportSchool} onSelectSchool={setReportSchool} selectedReportSchool={reportSchool} parentSelected={false} />}
            </div>
          </div>}
          <div className="card-footer justify-content-end d-flex flex-row">
            <button className="btn btn-sm btn-success" disabled={isLoading || setReportSchool === undefined} onClick={() => onViewReport()}>View Report</button>
          </div>
        </div>
      </div>}
      <div className={hideSelector ? "col-md-12" : "col-md-9"}>
        {isLoading && <div className="alert alert-warning"><i className="fa-solid fa-spin fa-spinner"></i> <strong>Loading report data</strong></div>}
        {report && reportSchool && reportData && <ReportDataView report={report} reportSchool={reportSchool} data={reportData} />}
      </div>
    </div>
  </div>;

  //{session.shared?.report && session.shared?.reportSchool ? <SurveyDataScreen report={session.shared.report} reportSchool={session.shared.reportSchool} /> : 
  return (<div>
    <p>You must have a report shared.</p>
  </div>);

}
